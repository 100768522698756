import __NS_DYNAMIC_REACT_CONTENT___0 from "./unauthed-footer.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { Bold, Disclosure } from 'legos/typography';
import { IssuerStatement } from 'apps/acp/packages/issuer-statement/react';
import { CopyrightStatement } from 'apps/acp/packages/content-copyright-statement';
import { isAndroid, isIOS, isWeb } from 'packages/platform-detector';
import { createUnAuthedGetBrandingRequest } from 'apps/acp/packages/webapi';
import { useHttpQuery } from 'packages/http-client/react';
import { showMobileAppsSection } from 'apps/acp/packages/mobile-apps-section-detector';
import { limitedUseAccessToken } from 'apps/acp/packages/limited-use-access-token';
import { AdaChatButton } from 'apps/acp/packages/ada-chatbot';
const isCordova = isAndroid() || isIOS();
export const UnauthedFooter = ({
  mfeConfig
}) => {
  const {
    brand
  } = useHttpQuery(limitedUseAccessToken(createUnAuthedGetBrandingRequest()));
  return createElement(Fragment, null, mfeConfig.showLoginTermsAndConditionsDisclosure && createElement(Disclosure, null, mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "a8fe13446d65167c5f6027b5b3c022c6",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), !mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "c248cd1c31138624f4b5be09db714a32",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.importantPatriotActDisclosure && createElement(Disclosure, {
    dangerouslySetInnerHTML: {
      __html: mfeConfig.importantPatriotActDisclosure
    }
  }), createElement(Disclosure, null, createElement(IssuerStatement, null)), mfeConfig.showMastercardDisclosures && createElement(Disclosure, null, createElement(Content, {
    hash: "1011f630d217106bc608d822ff62d6f9",
    devVariables: {},
    componentsAndExpressions: [() => !mfeConfig.showCardUseDisclosure && createElement(Fragment, {
      key: "showCardUseDisclosure"
    }, createElement("br", null), createElement("br", null), createElement(Content, {
      hash: "7ea1402ecb902a531e3ae08975f9fc7a",
      devVariables: {},
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }))],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.showShortAppleDisclosure && createElement(Disclosure, null, createElement(Content, {
    hash: "a9c895ef958ecb4fdf5ac4243ca43156",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !mfeConfig.showShortAppleDisclosure && !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "b1a7036c6d55e72b264f86f3a6eb6eeb",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "47427f593e673b6e2006ffd588dc3a1b",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), (!showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) || !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url)) && createElement(Disclosure, null, createElement(Content, {
    hash: "4d63b283be352fc0dbcbd36becebd96e",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Disclosure, null, createElement(CopyrightStatement, {
    statement: mfeConfig.copyrightStatement
  })), isCordova && createElement(Disclosure, {
    onClick: () => {
      var _a, _b;

      return ((_a = mfeConfig) === null || _a === void 0 ? void 0 : _a.mobileAppVersionOnClick) && ((_b = mfeConfig) === null || _b === void 0 ? void 0 : _b.mobileAppVersionOnClick(true));
    },
    style: {
      textAlign: 'center'
    }
  }, createElement(Content, {
    hash: "e8279ee7344a832d6d4e130ef068fd45",
    devVariables: {
      mfeConfig: mfeConfig
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), isWeb() && createElement(AdaChatButton, null));
};