export default { 
  h1011f630d217106bc608d822ff62d6f9: [[({}, [C0]) => ["Mastercard and the circles design are trademark of Mastercard International Incorporated.", C0()], {}]],
  h47427f593e673b6e2006ffd588dc3a1b: [[({}, []) => ["Google Play and the Google Play logo are trademarks of Google LLC."], {}]],
  h4d63b283be352fc0dbcbd36becebd96e: [[({}, []) => ["All other trademarks and service marks belong to their respective owners."], {}]],
  h7ea1402ecb902a531e3ae08975f9fc7a: [[({}, []) => ["Card may be used everywhere Debit Mastercard is accepted."], {}]],
  ha8fe13446d65167c5f6027b5b3c022c6: [[({}, []) => ["Login to see Terms & Conditions or Deposit Account Agreement and Privacy Policy."], {}]],
  ha9c895ef958ecb4fdf5ac4243ca43156: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. Apple Inc."], {}]],
  hb1a7036c6d55e72b264f86f3a6eb6eeb: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc."], {}]],
  hc248cd1c31138624f4b5be09db714a32: [[({}, []) => ["Login to see Terms & Conditions and Privacy Policy."], {}], [({}, []) => ["Login to see Deposit Account Agreement and Privacy Policy."], {
  "programType": ["consumer_dda"]
}]],
  he8279ee7344a832d6d4e130ef068fd45: [[({
  mfeConfig: mfeConfig
}, [C0]) => ["Version ", C0(mfeConfig.version)], {}]] 
}